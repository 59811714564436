@charset "utf-8";

/* Colors */
$primary-color: #303233 !default;
$text-color: #303233 !default;
$background-color: #eeeeee !default;
$muted-text-color: mix(#fff, $text-color, 30%) !default;
$link-color: #000 !default;
$link-color-hover: mix(#fff,$text-color,25%) !default;
$footer-background-color: $primary-color !default;
$border-color: mix(#fff,$muted-text-color,50%) !default;
$masthead-link-color: #000 !default;
$masthead-link-color-hover: mix(#fff,$text-color,25%) !default;

$small: 480px !default;

@import "minimal-mistakes/skins/air"; // skin
@import "minimal-mistakes"; // main partials

.page__footer {
  color: #eee !important; // override
}

.grid__item {
	.archive__item-excerpt {
	display: block;
    font-size: $type-size-5;

    @include breakpoint($medium) {
      display: block;
      font-size: $type-size-6;
    }
  }

  .archive__item-teaser {
  	max-height: 200px;

    @include breakpoint($medium) {
      max-height: 120px;
    }
  }
}

.no-show {
  display: none;
}